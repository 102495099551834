var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('jaya-connection-layout',{attrs:{"text":"Renseignez vos identifiants de connexion à un compte existant"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"autofocus":"","placeholder":"Email","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('signInUp.password')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1),_c('b-form-input',{attrs:{"type":_vm.passwordFieldType,"placeholder":_vm.$t('signInUp.password')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.error && _vm.error.response && _vm.error.response.data &&
                 _vm.error.response.data.detail === 'Login or password invalid.')?_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('signInUp.incorrectCredential'))+" ")]):(_vm.error)?_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('signInUp.technicalError'))+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"flex-grow-1",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',{staticClass:"pr-1 custom-control-primary",model:{value:(_vm.checkboxRememberMe),callback:function ($$v) {_vm.checkboxRememberMe=$$v},expression:"checkboxRememberMe"}},[_c('p',{staticClass:" text-dark"},[_vm._v(_vm._s(_vm.$t("signInUp.rememberMe")))])]),_c('a',{staticClass:"pb-1"},[_c('router-link',{attrs:{"to":"forgot-password"}},[_vm._v(" "+_vm._s(_vm.$t("signInUp.forgotPassword"))+" ")])],1)],1)],1),_c('b-col',{staticClass:"d-flex justify-content-around flex-wrap mb-1",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"m-25 flex-grow-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$router.push("register")}}},[_vm._v(" "+_vm._s(_vm.$t("signInUp.signUp"))+" ")]),_c('b-button',{staticClass:"m-25 flex-grow-1",attrs:{"disabled":invalid,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("signInUp.signIn"))+" ")])],1)],1)],1)]}}])})],1),_c('b-modal',{attrs:{"centered":"","hide-footer":"","title":"Compte inactif"},on:{"close":_vm.deactivatePopUp},model:{value:(_vm.popUpMoreInfo),callback:function ($$v) {_vm.popUpMoreInfo=$$v},expression:"popUpMoreInfo"}},[_c('p',[_vm._v(" Il semblerait que votre compte ne soit pas activé. L' e-mail de validation s'est peut-être caché dans vos spams. "),_c('br'),_vm._v(" Si vous ne le retrouvez pas, "),_c('span',{staticClass:"cursor-pointer font-medium text-primary",on:{"click":_vm.resendEmail}},[_vm._v(" cliquez ici ")]),_vm._v(" pour un nouvel envoi (l'envoi peut prendre jusqu'à quelques minutes). ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }