<template>
  <div>
    <jaya-connection-layout text="Renseignez vos identifiants de connexion à un compte existant">
      <validation-observer v-slot="{invalid, handleSubmit}">
        <b-form @submit.prevent="handleSubmit(login)">
          <b-row>

            <!-- Email -->
            <b-col cols="12">
              <b-form-group label="Email">
                <validation-provider v-slot="{errors}" rules="required" >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon"/>
                    </b-input-group-prepend>

                    <b-form-input v-model="email"
                        autofocus
                        placeholder="Email"
                        type="email"/>
                  </b-input-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Password -->
            <b-col cols="12">
              <b-form-group :label="$t('signInUp.password')">
                <validation-provider v-slot="{errors}" rules="required">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"/>
                    </b-input-group-prepend>
                    <b-form-input v-model="password"
                        :type="passwordFieldType"
                        :placeholder="$t('signInUp.password')"
                        v-on:keyup.enter="login"/>
                  </b-input-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                  <p v-if="error && error.response && error.response.data &&
                   error.response.data.detail === 'Login or password invalid.'"
                     class="text-danger">
                    {{ $t('signInUp.incorrectCredential') }}
                  </p>
                  <p v-else-if="error" class="text-danger">
                    {{ $t('signInUp.technicalError') }}
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Remember Me-->
            <b-col class="flex-grow-1" cols="12">
              <b-form-group class="d-flex justify-content-center">
                <b-form-checkbox v-model="checkboxRememberMe" class="pr-1 custom-control-primary">
                  <p class=" text-dark">{{ $t("signInUp.rememberMe") }}</p>
                </b-form-checkbox>
                <a class="pb-1">
                  <router-link to="forgot-password">
                    {{ $t("signInUp.forgotPassword") }}
                  </router-link>
                </a>
              </b-form-group>
            </b-col>

            <!-- Buttons-->
            <b-col class="d-flex justify-content-around flex-wrap mb-1" cols="12">
              <b-button class="m-25 flex-grow-1" variant="outline-primary"
                  @click="$router.push(`register`)">
                {{ $t("signInUp.signUp") }}
              </b-button>
              <b-button :disabled="invalid"
                  class="m-25 flex-grow-1"
                  type="submit"
                  variant="primary">
                {{ $t("signInUp.signIn") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </jaya-connection-layout>

    <b-modal
        v-model="popUpMoreInfo"
        centered
        hide-footer
        title="Compte inactif"
        @close="deactivatePopUp">
      <p> Il semblerait que votre compte ne soit pas activé. L' e-mail de validation s'est peut-être caché dans vos
        spams. <br>
        Si vous ne le retrouvez pas,
        <span class="cursor-pointer font-medium text-primary" @click="resendEmail">
              cliquez ici
            </span>
        pour un nouvel envoi
        (l'envoi peut prendre jusqu'à quelques minutes).
      </p>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BRow
} from "bootstrap-vue"
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from "vee-validate/dist/rules";

extend('required', {...required, message: 'Ce champ est requis'});

export default {
  name: 'login',
  components: {
    JayaConnectionLayout,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      email: "",
      password: "",
      checkboxRememberMe: true,
      error: null,
      activePopUp: true,
    };
  },
  beforeCreate() {
    //Delete all user data before connection
    this.$store.commit("DELETE_ALL_USER_DATA", null, {root: true})
  },
  mounted() {
    this.$i18n.locale =navigator.language.substring(0, 2);
    if (this.$route.hash.split('#')[1] === "disconnect") {
      this.$notify(
          {
            title: "Déconnecté",
            text: "Vous avez été déconnecté",
            time: 4000,
            color: "primary"
          }
      )
      this.$loading(false);
    }
  },
  methods: {
    login() {
      this.$loading(true);
      this.$store.dispatch("account/login", {
        login: this.email,
        password: this.password,
        remember_me: this.checkboxRememberMe
      })
          .then(() => {
            this.$notify({
              time: 4000,
              color: "primary",
              title: this.$t('signInUp.loggedIn'),
              text: this.$t('signInUp.welcomeBack')
            });
            this.$router.push({name: "coupe-de-france-du-potager"});
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              title: this.$t('Error'),
              text: this.$t('signInUp.loginFailed'),
              color: "danger"
            });
            this.$loading(false);
          });
    },
    resendEmail() {
      let payload = {email: this.email};
      this.$store.dispatch("account/resend_validation_email", payload)
          .then(() => {
            this.$notify({
              title: this.$t('signInUp.emailResentTitle'),
              text: this.$t('signInUp.emailResentText', { email: this.email }),
              time: 4000,
              color: "primary"
            });
          })
          .catch(() => {
            this.$notify({
              title: this.$t('Error'),
              text: this.$t('signInUp.emailResendFailed'),
              time: 4000,
              color: "danger"
            });
          });
    },
    deactivatePopUp() {
      this.activePopUp = false
    },
  },
  computed: {
    popUpMoreInfo() {
      return this.$store.state.account.userIsInactive && this.activePopUp
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  }
}
</script>

<style lang="scss">
.google-button {
  background: #db4a39 !important;
  border-width: 0;
}

.google-button:hover {
  box-shadow: 0 8px 25px -8px #db4a39 !important;
}

.facebook-button {
  background: #3b5998 !important;
  border-width: 0;
}

.facebook-button:hover {
  box-shadow: 0 8px 25px -8px #3b5998 !important;
}
</style>
